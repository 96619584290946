var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var CK,EK,GK,JK,MK;$CLJS.AK=function(a){return $CLJS.Hh($CLJS.q($CLJS.gB),a,$CLJS.mF)};$CLJS.BK=new $CLJS.M("lib","source-name","lib/source-name",1931622490);CK=new $CLJS.M("metabase.lib.schema.ref","aggregation-options","metabase.lib.schema.ref/aggregation-options",-15566487);$CLJS.DK=new $CLJS.M(null,"operators","operators",-2064102509);EK=new $CLJS.M("metabase.lib.schema.ref","field.options","metabase.lib.schema.ref/field.options",408316031);
$CLJS.FK=new $CLJS.M(null,"stage-number","stage-number",-1752729638);GK=new $CLJS.M("metabase.lib.schema.ref","field.literal","metabase.lib.schema.ref/field.literal",-1228060748);$CLJS.HK=new $CLJS.M(null,"min-value","min-value",-1119123315);$CLJS.IK=new $CLJS.M(null,"dimensions","dimensions",-254818097);JK=new $CLJS.M("metabase.lib.schema.ref","field.id","metabase.lib.schema.ref/field.id",-956546909);$CLJS.KK=new $CLJS.M(null,"max-value","max-value",687805168);
$CLJS.LK=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);MK=new $CLJS.M("metabase.lib.schema.ref","field.literal.options","metabase.lib.schema.ref/field.literal.options",-860446668);$CLJS.NK=new $CLJS.M("metabase.lib.drill-thru","drill-thru","metabase.lib.drill-thru/drill-thru",-67424984);$CLJS.Y(EK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.uD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oF,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.bG],null)],null)],null));$CLJS.Y(MK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,EK,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tA,$CLJS.yD],null)],null)],null));$CLJS.Y(GK,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.hr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.iF],null),MK,$CLJS.oD],null));
$CLJS.Y(JK,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.hr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.iF],null),EK,$CLJS.iK],null));
$CLJS.IF.g($CLJS.iF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.hr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.iF],null),EK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,$CLJS.iK,$CLJS.oD],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kr,new $CLJS.h(null,2,[$CLJS.xi,function(a){return $CLJS.rd(a)?($CLJS.I(a,0,null),$CLJS.I(a,1,null),a=$CLJS.I(a,2,null),$CLJS.XA(a)):null},$CLJS.ts,"Invalid :field clause ID or name: must be a string or integer"],null),new $CLJS.P(null,2,
5,$CLJS.Q,[$CLJS.lA,JK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kA,GK],null)],null)],null));$CLJS.oE($CLJS.iF,$CLJS.mF);$CLJS.JD.m(null,$CLJS.iF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.oi,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.XD});$CLJS.vE($CLJS.qA,$CLJS.H([$CLJS.oD]));$CLJS.JD.m(null,$CLJS.qA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.oi,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.XD});
$CLJS.oE($CLJS.qA,$CLJS.mF);$CLJS.Y(CK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.uD,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.U,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.oD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.oD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BK,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.oD],null)],null)],null));
$CLJS.IF.g($CLJS.YE,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.hr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.YE],null),CK,$CLJS.uj],null));$CLJS.JD.m(null,$CLJS.YE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.oi,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.XD});$CLJS.oE($CLJS.YE,$CLJS.mF);$CLJS.vE($CLJS.GE,$CLJS.H([$CLJS.Zr,$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.NJ],null)],null)]));$CLJS.oE($CLJS.GE,$CLJS.mF);
$CLJS.vE($CLJS.dF,$CLJS.H([$CLJS.Zr,$CLJS.XD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.LJ],null)],null)]));$CLJS.oE($CLJS.dF,$CLJS.mF);
$CLJS.Y($CLJS.mF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,$CLJS.LD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.h(null,1,[$CLJS.Es,function(){return["Valid reference, must be one of these clauses: ",$CLJS.hs(", ",$CLJS.Cz($CLJS.Dd,$CLJS.yk.g($CLJS.q($CLJS.gB),$CLJS.mF)))].join("")}],null),function(a){a=$CLJS.I(a,0,null);return $CLJS.AK(a)}],null)],null));