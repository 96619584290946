var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.convert.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.query.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.malli.js");
'use strict';$CLJS.f1=function(a,b){var c=$CLJS.FV(b);return $CLJS.n(c)?$CLJS.d_(a,c):$CLJS.b_(a,b)};$CLJS.g1=function(a){var b=$CLJS.XA(a);b=b instanceof $CLJS.M?b.S:null;switch(b){case "dispatch-type/nil":return null;case "field":return $CLJS.tO.h($CLJS.DD(a));case "metadata/column":return $CLJS.cZ.h(a);case "mbql/join":return $CLJS.UD.h(a);default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}};
$CLJS.h1=function(a,b){return $CLJS.ha.j?$CLJS.ha.j("%s__%s",a,b):$CLJS.ha.call(null,"%s__%s",a,b)};$CLJS.i1=function(a,b){var c=$CLJS.g1(b);if($CLJS.n(c))a=c;else a:{var d=$CLJS.O(b);c=$CLJS.J.g(d,$CLJS.$J);d=$CLJS.J.g(d,$CLJS.aK);if($CLJS.n($CLJS.n(c)?d:c)&&(d=$CLJS.f1(a,d),$CLJS.n(d))){d=$CLJS.U.h(d);a=$CLJS.U.h($CLJS.Y0(a,c));a=$CLJS.ha.j?$CLJS.ha.j("%s__via__%s",d,a):$CLJS.ha.call(null,"%s__via__%s",d,a);break a}a=null}return $CLJS.n(a)?$CLJS.h1(a,$CLJS.U.h(b)):$CLJS.U.h(b)};
$CLJS.j1=function(a,b,c,d){var e=$CLJS.Wf.j($CLJS.Sg,$CLJS.cf.h($CLJS.aK),c);return $CLJS.Wf.j($CLJS.xf,$CLJS.dk.l($CLJS.ef($CLJS.YJ),$CLJS.ef($CLJS.Hi),$CLJS.ef($CLJS.dk.g($CLJS.Zk,$CLJS.Hi)),$CLJS.H([$CLJS.cf.h(function(f){f=$CLJS.O(f);var k=$CLJS.J.g(f,$CLJS.Hi),l=$CLJS.J.g(f,$CLJS.YJ);return $CLJS.R.l($CLJS.Y0(a,l),$CLJS.A_,k,$CLJS.H([$CLJS.B_,$CLJS.cZ.h(f)]))}),$CLJS.ek.h(function(f){return $CLJS.Cd(e,$CLJS.aK.h(f))}),$CLJS.dD(function(f){f=$CLJS.O(f);var k=$CLJS.J.g(f,$CLJS.aK),l=$CLJS.J.g(f,
$CLJS.A_),m=$CLJS.J.g(f,$CLJS.B_);f=$CLJS.b_(a,k);return function v(u){return new $CLJS.ie(null,function(){for(;;){var x=$CLJS.y(u);if(x){if($CLJS.vd(x)){var A=$CLJS.ic(x),C=$CLJS.D(A),G=$CLJS.le(C);return function(){for(var X=0;;)if(X<C){var T=$CLJS.hd(A,X),da=$CLJS.R.l(T,$CLJS.$J,l,$CLJS.H([$CLJS.x_,m,$CLJS.MJ,$CLJS.FY,$CLJS.uY,$CLJS.U.h(T)]));T=G;var oa=$CLJS.R,Ma=oa.j,sb=da;da=$CLJS.i1(a,da);da=d.h?d.h(da):d.call(null,da);oa=Ma.call(oa,sb,$CLJS.JY,da);T.add(oa);X+=1}else return!0}()?$CLJS.oe($CLJS.qe(G),
v($CLJS.jc(x))):$CLJS.oe($CLJS.qe(G),null)}var K=$CLJS.z(x),S=$CLJS.R.l(K,$CLJS.$J,l,$CLJS.H([$CLJS.x_,m,$CLJS.MJ,$CLJS.FY,$CLJS.uY,$CLJS.U.h(K)]));return $CLJS.ae($CLJS.R.j(S,$CLJS.JY,function(){var X=$CLJS.i1(a,S);return d.h?d.h(X):d.call(null,X)}()),v($CLJS.Hc(x)))}return null}},null,null)}($CLJS.V_.v(a,b,f,new $CLJS.h(null,2,[$CLJS.F_,d,$CLJS.z_,!1],null)))})])),c)};$CLJS.k1=function(a,b){return $CLJS.b1.g(a,b)};$CLJS.l1=function(a){return $CLJS.eD("Question {0}",$CLJS.H([$CLJS.xh.l($CLJS.H([a]))]))};
$CLJS.m1=function(a,b){var c=$CLJS.QD.h(b);$CLJS.n(c)||(c=$CLJS.PY.h(b),$CLJS.n(c)||(c=$CLJS.OY.h(b),c=null!=c?$CLJS.U_.h($CLJS.k1(a,$CLJS.WX.h(c))):null));return $CLJS.n(c)?(c=$CLJS.Be($CLJS.sd(c)?$CLJS.BV.h(c):$CLJS.rd(c)?c:null),$CLJS.n(c)?$CLJS.fk.g($CLJS.Xs(Rpa,a,b),c):null):null};$CLJS.n1=new $CLJS.M("metabase.lib.card","force-broken-id-refs","metabase.lib.card/force-broken-id-refs",-868945458);$CLJS.o1=new $CLJS.M(null,"field-ref","field-ref",-1699303428);$CLJS.K_.m(null,$CLJS.jK,function(a,b,c){return $CLJS.zz($CLJS.jD,$CLJS.U)(c)});$CLJS.Q_.m(null,$CLJS.jK,function(a,b,c){a=$CLJS.O(c);b=$CLJS.J.g(a,$CLJS.U);c=$CLJS.J.g(a,$CLJS.jD);return $CLJS.Ra(c)?$CLJS.R.j(a,$CLJS.jD,$CLJS.e1.g($CLJS.xA,b)):a});
$CLJS.V_.m(null,$CLJS.jK,function(a,b,c,d){c=$CLJS.O(c);var e=$CLJS.J.g(c,$CLJS.QD),f=$CLJS.J.g(c,$CLJS.PY);d=$CLJS.O(d);var k=$CLJS.J.g(d,$CLJS.z_),l=$CLJS.J.g(d,$CLJS.F_);return $CLJS.bf.g($CLJS.U_.v(a,b,c,d),$CLJS.n(k)?$CLJS.j1(a,b,$CLJS.bf.g(e,f),l):null)});$CLJS.N_.m(null,$CLJS.NU,function(a,b){var c=$CLJS.EV(a,b);c=$CLJS.O(c);c=$CLJS.J.g(c,$CLJS.NU);if($CLJS.n(c)){var d=$CLJS.d_(a,c);a=$CLJS.n(d)?$CLJS.j_.v(a,b,d,$CLJS.k_):null;return $CLJS.n(a)?a:$CLJS.l1(c)}return null});
var Rpa=function(){function a(d,e,f){var k=$CLJS.Wj.g($CLJS.Ez(f,$CLJS.eA),$CLJS.o1);f=$CLJS.vk.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.tA,$CLJS.cj,$CLJS.Vs,$CLJS.KJ],null),function(){var l=$CLJS.Hi.h(k);if($CLJS.n(l))try{return $CLJS.Y0(d,l)}catch(m){return null}else return null}(),k,new $CLJS.h(null,3,[$CLJS.Vs,$CLJS.KJ,$CLJS.MJ,$CLJS.MY,$CLJS.uY,$CLJS.zz($CLJS.uY,$CLJS.U)(k)],null)]));f=$CLJS.n(e)?$CLJS.R.j(f,$CLJS.WY,$CLJS.U0(e)):f;e=$CLJS.n($CLJS.n(!0)?$CLJS.Ra(e)||$CLJS.Ra($CLJS.BY.h($CLJS.d_(d,
$CLJS.U0(e)))):!0)?$CLJS.R.j(f,$CLJS.n1,!0):f;return $CLJS.ck.g($CLJS.rj.h(k),$CLJS.Xh)?$CLJS.R.j(e,$CLJS.YJ,null):e}function b(d,e){return c.j?c.j(d,null,e):c.call(null,d,null,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.T_.m(null,$CLJS.jK,function(a,b,c,d){b=$CLJS.O(d);var e=$CLJS.J.g(b,$CLJS.F_);return $CLJS.fk.g(function(f){var k=$CLJS.qB($CLJS.JY,$CLJS.uY,$CLJS.U)(f);return $CLJS.R.j(f,$CLJS.JY,e.h?e.h(k):e.call(null,k))},$CLJS.m1(a,c))});